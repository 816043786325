import React from "react";
import { TittleSection } from "../TittleSection";
import style from "../../assets/styles/section.module.css";
import logo_eatu from "../../assets/images/Home/eatu.png";
import logo_helena from "../../assets/images/Home/helena.jpg";
import logo_vien_khoa_hoc from "../../assets/images/Home/logoWASI-removebg.png";
import logo_htx from "../../assets/images/Home/htx-nss.png";
import logo_dq from "../../assets/images/Home/logo-dienquang.webp";
import logo_dhnl from "../../assets/images/Home/daihocnonglam.png";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

interface CardInforProps {
  image: string;
  name: string;
  description: string;
  imageClassName?: string;
}

export const CoopSection = () => {
  const { t } = useTranslation();

  const CardInfor = ({
    image,
    name,
    description,
    imageClassName,
  }: CardInforProps) => {
    return (
      <div className="p-4">
        <div className="w-full flex justify-center items-center h-28">
          <img src={image} className={imageClassName} alt="" />
        </div>
        <p className="text-center font-bold my-6">{name}</p>
        <p className={style.cardInfoDesc}>{description}</p>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 3000, // Interval for autoplay in milliseconds (3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const partnerData = [
    {
      image: logo_vien_khoa_hoc,
      name: t("home_section_partner1_name"),
      description: t("home_section_parter1_desc"),
    },
    {
      image: logo_eatu,
      name: t("home_section_partner2_name"),
      description: t("home_section_parter2_desc"),
    },
    {
      image: logo_helena,
      name: t("home_section_partner3_name"),
      description: t("home_section_parter3_desc"),
    },
    {
      image: logo_htx,
      name: t("home_section_partner4_name"),
      description: t("home_section_parter4_desc"),
    },
    {
      image: logo_dq,
      name: t("home_section_partner5_name"),
      description: t("home_section_parter5_desc"),
    },
    {
      image: logo_dhnl,
      name: t("home_section_partner6_name"),
      description: t("home_section_parter6_desc"),
    },
  ];

  return (
    <div className={`${style.section} bg-[#F1F1EA]`}>
      <TittleSection title={t("home_section_partner_title")} />
      <div className="bootstrap-wrapper">
        <Slider {...settings}>
          {partnerData.map((partner, index) => (
            <CardInfor
              key={index}
              image={partner.image}
              name={partner.name}
              description={partner.description}
              imageClassName="h-full cardInfoImage"
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};
