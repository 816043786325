import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useState } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { TittleSection } from "../TittleSection";
import styleSection from "./styles.module.css";
import style from "../../assets/styles/section.module.css";
import touchstone from "../../assets/images/Sponsor/Touchstone.png";
import aiviet from "../../assets/images/Sponsor/AiViet.png";
import conservation from "../../assets/images/Sponsor/Conservation.png";
import p4g from "../../assets/images/Sponsor/P4G.png";
import qualcomm from "../../assets/images/Sponsor/Qualcomm.png";
import giz from "../../assets/images/Sponsor/GIZ.png";

import { useTranslation } from "react-i18next";

const settings = {
  dots: true,
  infinite: true,
  speed: 2400,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2400,
  arrows: false,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
        arrows: false,
        speed: 2400,
      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 0,
        arrows: false,
        speed: 2400,
      },
    },
    {
      breakpoint: 479,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 0,
        arrows: false,
        speed: 2400,
      },
    },
  ],
};

const CertSection = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxImages, setLightboxImages] = useState([]);

  const imagesSponsors = [
    {
      src: touchstone,
      alt: t("sponsor.touchstone.alt"),
      top: t("sponsor.touchstone.top"),
      title: t("sponsor.touchstone.title"),
    },
    {
      src: aiviet,
      alt: t("sponsor.aiviet.alt"),
      top: t("sponsor.aiviet.top"),
      title: t("sponsor.aiviet.title"),
    },
    {
      src: conservation,
      alt: t("sponsor.conservation.alt"),
      top: t("sponsor.conservation.top"),
      title: t("sponsor.conservation.title"),
    },
    {
      src: p4g,
      alt: t("sponsor.p4g.alt"),
      top: t("sponsor.p4g.top"),
      title: t("sponsor.p4g.title"),
    },
    {
      src: qualcomm,
      alt: t("sponsor.qualcomm.alt"),
      top: t("sponsor.qualcomm.top"),
      title: t("sponsor.qualcomm.title"),
    },
    {
      src: giz,
      alt: t("sponsor.giz.alt"),
      top: t("sponsor.giz.top"),
      title: t("sponsor.giz.title"),
    },
  ];

  const openLightbox = (images, index) => {
    setLightboxImages(images);
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className={style.section}>
      <div className={styleSection.bootstrapWrapper}>
        <TittleSection title={t("cert_section_title_sponsor")} />
        <div className="slider-container">
          <Slider {...settings} className={styleSection.prize}>
            {imagesSponsors.map((item, index) => (
              <div
                key={index}
                className={styleSection.prizeBlocks}
                onClick={() => openLightbox(imagesSponsors, index)}
              >
                <div className={styleSection.prizeImageWrapper}>
                  <div className={styleSection.prizeImage}>
                    <img
                      src={item.src}
                      alt={item.alt}
                      className={styleSection.carouselImg}
                    />
                  </div>
                </div>
                <div className={styleSection.prizeInfo}>
                  <div className={styleSection.top}>{item.title}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[photoIndex].src}
          nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length].src}
          prevSrc={
            lightboxImages[
              (photoIndex + lightboxImages.length - 1) % lightboxImages.length
            ].src
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + lightboxImages.length - 1) % lightboxImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lightboxImages.length)
          }
        />
      )}
    </div>
  );
};

export default CertSection;
