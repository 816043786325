import React from "react";
import { Carousel } from "../../components/Carousel";
import style from "../../assets/styles/section.module.css";
import styleCarousel from "../../assets/styles/carousel.module.css";
import { TittleSection } from "../TittleSection";
import ImgKnowledge_1 from "../../assets/images/Knowledge/anh2.jpeg";
import ImgKnowledge_2 from "../../assets/images/Knowledge/anh7.jpeg";
import ImgKnowledge_3 from "../../assets/images/Knowledge/anh9.jpg";
import { CaretRight } from "../../assets/images/svg/Icon";
import { useTranslation } from "react-i18next";

interface MiniPostProps {
  image: string;
  date: string;
  title: string;
  content: string;
  link?: string;
}

export const KnowledgeSection = () => {
  const { t } = useTranslation();
  const shorten = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.slice(0, maxLength) + "...";
    }
    return title;
  };
  const MiniPost = ({ image, date, title, content, link }: MiniPostProps) => {
    return (
      <div className="p-4">
        <div className={styleCarousel.minipost_carousel}>
          <div className="w-full a-16-9">
            <img
              src={image}
              className="object-cover object-center h-full w-full"
              alt=""
            />
          </div>
          <div className="p-4 min-h-[200px]">
            <p className={style.date}>{date}</p>
            <h3 className={style.title}>{shorten(title, 50)}</h3>
            <p className={style.content}>{shorten(content, 130)}</p>
            <button className={style.read_more}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {t("read_more")}
                <CaretRight />
              </a>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${style.section} bg-[#F1F1EA]`}>
      <TittleSection title={t("home_section_knowledge_title")} />

      <div className="relative w-full">
        <Carousel autoplaySpeed={1000000000} showArrows={true}>
          <MiniPost
            image={
              "https://enfarm-buckets.enfarm.com/enfarm-post/soil-secret1.jpg"
            }
            date={t("home_section_knowledge_post5_date")}
            title={t("home_section_knowledge_post5_title")}
            content={t("home_section_knowledge_post5_subtitle")}
            link={t("links.knowledge-5")}
          />
          <MiniPost
            image={
              "https://enfarm-buckets.enfarm.com/enfarm-post/ph-measurement.jpg"
            }
            date={t("home_section_knowledge_post0_date")}
            title={t("home_section_knowledge_post0_title")}
            content={t("home_section_knowledge_post0_subtitle")}
            link={t("links.knowledge-0")}
          />
          <MiniPost
            image={ImgKnowledge_1}
            date={t("home_section_knowledge_post1_date")}
            title={t("home_section_knowledge_post1_title")}
            content={t("home_section_knowledge_post1_subtitle")}
            link={t("links.knowledge-1")}
          />
          <MiniPost
            image={ImgKnowledge_2}
            date={t("home_section_knowledge_post2_date")}
            title={t("home_section_knowledge_post2_title")}
            content={t("home_section_knowledge_post2_subtitle")}
            link={t("links.knowledge-2")}
          />
          <MiniPost
            image={ImgKnowledge_3}
            date={t("home_section_knowledge_post3_date")}
            title={t("home_section_knowledge_post3_title")}
            content={t("home_section_knowledge_post3_subtitle")}
            link={t("links.knowledge-3")}
          />
          <MiniPost
            image={ImgKnowledge_1}
            date={t("home_section_knowledge_post1_date")}
            title={t("home_section_knowledge_post1_title")}
            content={t("home_section_knowledge_post1_subtitle")}
            link={t("links.knowledge-1")}
          />
          <MiniPost
            image={ImgKnowledge_2}
            date={t("home_section_knowledge_post2_date")}
            title={t("home_section_knowledge_post2_title")}
            content={t("home_section_knowledge_post2_subtitle")}
            link={t("links.knowledge-2")}
          />
          <MiniPost
            image={ImgKnowledge_3}
            date={t("home_section_knowledge_post3_date")}
            title={t("home_section_knowledge_post3_title")}
            content={t("home_section_knowledge_post3_subtitle")}
            link={t("links.knowledge-3")}
          />
        </Carousel>
      </div>
    </div>
  );
};
